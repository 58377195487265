<template>
  <section class="content-header" style="padding: 8px;">
    <div class="container-fluid">
      <div class="row" v-if="routes.length > 0">
        <div class="col-sm-6">
          <ol class="breadcrumb" id="breadcrumb-content">
            <li v-for="(v, i) in routes" :key="i" class="breadcrumb-item">
              <router-link :to="v.path">{{ v.name }}</router-link>
            </li>
            <li class="breadcrumb-item active">{{ $route.name }}</li>
          </ol>
        </div>
        <div class="col-sm-6 size-website" id="btn-back-content">
          <button class="float-right btn btn-xs btn-outline-info" v-on:click="$router.go(-1)"><i class="fas fa-undo mr-2"></i>Quay lại</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      routes: []
    };
  },
  methods: {
    getRoutes() {
      this.routes = [];
      if (this.$route.path) {
        const paths = this.$route.path.split("/");
        if (paths && paths.length > 0) {
          paths.forEach(p => {
            if (p && `/${p}` != this.$route.path &&
              this.$router.options && this.$router.options.routes && this.$router.options.routes.length > 0) {
              const routes = this.$router.options.routes.filter(r => r.path == `/${p}`);
              if (routes && routes.length > 0) this.routes.push(routes[0]);
            }
          });
        }
      }
    }
  },
  mounted() {
    this.getRoutes();
  },
  watch: {
    $route() {
      this.getRoutes();
    }
  }
};
</script>